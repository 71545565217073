var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right-panel"},[(!Object.keys(_vm.song || {}).length)?_c('div'):_c('div',{staticClass:"panel-container"},[_c('div',{staticClass:"panel-header"},[_vm._v(" "+_vm._s(_vm.song.name)+" ")]),_c('div',{staticClass:"collaborators"},[(_vm.companyOptions.applicable && _vm.song.collaborators.length > 1)?_c('div',{staticClass:"card company"},[_c('div',{staticClass:"step"},[_c('span',{staticClass:"question"},[_vm._v(" Who would be entering into this agreement? ")]),_c('div',{staticClass:"inputs"},[_c('div',{staticClass:"radiobuttons"},_vm._l((_vm.companyQuestion.choices),function(choice,i){return _c('div',{key:i,staticClass:"radio-group"},[_c('label',{staticClass:"radio"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyQuestion.answer),expression:"companyQuestion.answer"}],attrs:{"type":"radio"},domProps:{"value":choice,"checked":_vm._q(_vm.companyQuestion.answer,choice)},on:{"change":function($event){return _vm.$set(_vm.companyQuestion, "answer", choice)}}}),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(choice.label)+" "),(choice.value)?[_c('br'),_vm._v(" + "),_c('br'),_vm._v(" "+_vm._s(_vm.artist.name)+" ")]:_vm._e()],2),_c('span',{staticClass:"checkmark"})])])}),0)])]),_c('button',{staticClass:"primary outline",attrs:{"disabled":_vm.submitting === _vm.song.id},on:{"click":_vm.updateCompanyData}},[_vm._v(" "+_vm._s(_vm.submitting ? "Submitting" : "Continue")+" ")])]):_vm._e(),(!_vm.companyOptions.applicable || _vm.song.collaborators.length === 1)?_vm._l((_vm.song.collaborators),function(ref,i){
          var info = ref.info;
          var services = ref.services;
          var percentage = ref.percentage; if ( percentage === void 0 ) percentage = {};
          var isMe = ref.isMe;
          var songHost = ref.songHost;
          var hostActive = ref.hostActive;
          var status = ref.status;
          var deal_id = ref.deal_id;
          var fee_status = ref.fee_status;
          var inducement = ref.inducement;
          var category = ref.category;
return _c('div',{key:i,staticClass:"card"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"artist"},[(info.avatar_url)?_c('img',{attrs:{"src":info.avatar_url}}):_c('div',{staticClass:"initials"},[_vm._v(" "+_vm._s(_vm._f("initials")(info.name))+" ")]),_c('div',{staticClass:"details"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(info.name))]),(!songHost)?_c('span',{staticClass:"contribution"},[_vm._v(" "+_vm._s(_vm._f("contribution")(services))+" ")]):_vm._e()])]),(!i)?_c('div',{staticClass:"party-icon"},[_vm._v("HOST")]):(category === 'completed')?_c('div',{staticClass:"check"},[_c('img',{attrs:{"src":require("../assets/icons/Check.svg"),"width":"16","height":"16"}})]):(category === 'action-needed')?_c('div',{staticClass:"attention"},[_c('img',{attrs:{"src":require("../assets/icons/Attention.svg"),"width":"16","height":"16"}})]):_vm._e()]),(isMe || _vm.song.isHost)?_c('div',{staticClass:"split"},[_c('div',[_c('span',{staticClass:"type"},[_vm._v("Recording")]),_c('span',{staticClass:"percentage",class:{
                  invalid:
                    percentage.recording > 100 || percentage.recording < 0
                }},[_vm._v(" "+_vm._s(percentage.recording + "%")+" ")])]),_c('div',[_c('span',{staticClass:"type"},[_vm._v("Publishing")]),_c('span',{staticClass:"percentage",class:{
                  invalid:
                    percentage.publishing > 100 || percentage.publishing < 0
                }},[_vm._v(" "+_vm._s(percentage.publishing + "%")+" ")])])]):_vm._e(),(!songHost)?_c('div',{staticClass:"buttons"},[(status === 'pending')?_c('button',{staticClass:"primary",on:{"click":function($event){return _vm.openModal('create', deal_id, status, info, hostActive)}}},[_vm._v(" Create Deal ")]):_vm._e(),(
                (isMe || _vm.song.isHost) &&
                  (status.includes('deal-updated-') ||
                    status.includes('contract-signed-'))
              )?_c('button',{staticClass:"primary",on:{"click":function($event){return _vm.openModal('review', deal_id, status, info, hostActive)}}},[_vm._v(" Review Deal ")]):_vm._e(),((isMe || _vm.song.isHost) && status === 'contract-ready')?_c('button',{staticClass:"primary",on:{"click":function($event){return _vm.openModal('view', deal_id, status, info, hostActive)}}},[_vm._v(" View Contract ")]):_vm._e(),(status === 'contract-ready' && inducement.applicable)?_c('button',{staticClass:"primary outline",on:{"click":function($event){return _vm.openModal(
                  'inducement',
                  deal_id,
                  status,
                  info,
                  hostActive,
                  inducement
                )}}},[_vm._v(" "+_vm._s(inducement.signature ? "View " : "Sign ")+" Inducement Contract ")]):_vm._e(),(
                hostActive &&
                  status === 'contract-ready' &&
                  ['requires_capture', 'processing'].includes(fee_status)
              )?_c('button',{staticClass:"primary",attrs:{"disabled":fee_status === 'processing' || _vm.submitting === deal_id},on:{"click":function($event){return _vm.releaseFunds(deal_id, _vm.song.id)}}},[_vm._v(" "+_vm._s(_vm.submitting === deal_id ? "Submitting..." : "Release Funds")+" ")]):_vm._e()]):_vm._e()])}):_vm._e()],2),(_vm.song.isHost)?_c('div',{staticClass:"add-collaborator"},[_c('button',{staticClass:"primary",on:{"click":function($event){_vm.addingCollaborator = true}}},[_c('img',{attrs:{"src":require("../assets/icons/User.svg")}}),_vm._v(" Add Collaborator ")])]):_vm._e()]),(_vm.addingCollaborator)?_c('AddCollaborator',{attrs:{"song":_vm.song},on:{"close":function($event){_vm.addingCollaborator = false}}}):_vm._e(),(_vm.modalName === 'create-deal')?_c('CreateDeal',{attrs:{"deal":_vm.currentDeal},on:{"close":_vm.clearDeal}}):_vm._e(),(_vm.modalName === 'review-deal')?_c('ReviewDeal',{attrs:{"deal":_vm.currentDeal},on:{"close":_vm.clearDeal}}):_vm._e(),(_vm.modalName === 'view-contract')?_c('ViewContract',{attrs:{"deal":_vm.currentDeal},on:{"close":_vm.clearDeal}}):_vm._e(),(_vm.modalName === 'project-funding')?_c('ProjectFunding',{attrs:{"deal":_vm.currentDeal},on:{"close":_vm.clearDeal}}):_vm._e(),(_vm.modalName === 'inducement-contract')?_c('InducementContract',{attrs:{"deal":_vm.currentDeal,"inducement":_vm.inducement},on:{"close":_vm.clearDeal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }