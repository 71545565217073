<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close', 'cancelled')" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>ADD A COLLABORATOR</h4>
              <div class="existing" v-if="!addingNew">
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="value.name"
                      id="artist_name"
                      type="text"
                      class="text-input dark"
                      placeholder="Search Artists and Producers..."
                      autocomplete="no"
                    />
                  </div>
                </div>
                <span class="switch-type" @click="switchType">
                  Or Invite the Artist/Producer to Nvoko
                </span>
                <div class="search-title" v-if="resultsAvailable">
                  <b>Search Results </b>
                  <i>(Showing {{ results.length }} of {{ total }})</i>
                </div>
                <div class="results-container" v-if="!fieldsEmpty">
                  <div class="results" v-if="resultsAvailable">
                    <div
                      class="result"
                      v-for="(data, i) in results"
                      :key="i"
                      @click="selectResult(data)"
                    >
                      <div class="initials">
                        {{ data.name | initials }}
                      </div>
                      <div class="details">
                        <span>{{ data.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="searching" v-if="searching">
                    Searching...
                  </div>
                  <div class="fetch-more" v-if="canFetchMore" @click="loadMore">
                    + Load More Results
                  </div>
                  <div class="no-results" v-if="total === 0">
                    No Search Results found
                  </div>
                </div>
              </div>
              <div class="adding-new" v-else>
                <div class="inputs">
                  <div class="form-group names">
                    <input
                      v-model="value.user.first_name"
                      id="first_name"
                      type="text"
                      class="text-input dark"
                      placeholder="First name"
                    />
                    <input
                      v-model="value.user.last_name"
                      id="last_name"
                      type="text"
                      class="text-input dark"
                      placeholder="Last name"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="value.user.email"
                      id="email"
                      type="email"
                      class="text-input dark"
                      placeholder="Email address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      v-model="value.name"
                      id="artist_name"
                      type="text"
                      class="text-input dark"
                      placeholder="Artist/Producer Name"
                    />
                  </div>
                </div>
                <span class="switch-type" @click="switchType">
                  Search Artists/Producers on Nvoko instead
                </span>
              </div>
              <div class="buttons">
                <button
                  class="primary outline dark"
                  @click="$emit('close', 'cancelled')"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="$emit('close', 'success')"
                  :disabled="!valid"
                >
                  {{ isSelectionActive ? "Add" : "Invite" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import gql from "graphql-tag";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

const SEARCH = gql`
  query($limit: Int!, $offset: Int!, $where: artists_bool_exp!) {
    artists(where: $where, limit: $limit, offset: $offset) {
      id
      name
    }
    artists_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const initialState = addingNew => ({
  offset: 0,
  results: [],
  total: null,
  fetchingMore: false,
  selected: {},
  addingNew: !!addingNew
});

export default {
  name: "AddPerson",
  components: {
    ModalCloseIcon
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return initialState();
  },
  watch: {
    searchData(data) {
      this.results = data.results;
      this.total = data.total;
    },
    fields() {
      if (this.searchDisabled) return;
      if (this.value.id) delete this.value.id;
      Object.assign(this.$data, initialState());
    },
    addingNew(value) {
      this.$emit("input", value ? { user: {} } : {});
    }
  },
  computed: {
    fields() {
      return JSON.stringify(this.value);
    },
    fieldsEmpty() {
      return !this.value.name;
    },
    searchDisabled() {
      const { fieldsEmpty, isSelectionActive, addingNew } = this;
      return fieldsEmpty || isSelectionActive || addingNew;
    },
    searching() {
      return this.$apollo.queries.searchData.loading;
    },
    resultsAvailable() {
      return (
        this.results &&
        this.results.length &&
        (!this.searching || this.fetchingMore) &&
        !this.fieldsEmpty &&
        !this.searchDisabled
      );
    },
    canFetchMore() {
      const { results, resultsAvailable, total, searching } = this;
      return resultsAvailable && results.length < total && !searching;
    },
    isSelectionActive() {
      return JSON.stringify(this.value) === this.selected;
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    switchType() {
      Object.assign(this.$data, initialState(!this.addingNew));
    },
    loadMore() {
      this.offset += 10;
      this.fetchingMore = true;
    },
    selectResult(data) {
      const { id, name } = data;
      const artist = { id, name };
      this.selected = JSON.stringify(artist);
      this.$emit("input", artist);
    }
  },
  apollo: {
    searchData: {
      query: SEARCH,
      variables() {
        const {
          value: { name },
          offset
        } = this;
        return {
          offset,
          limit: 10,
          where: {
            name: { _ilike: `%${name || ""}%` }
          }
        };
      },
      update({
        artists_aggregate: {
          aggregate: { count }
        },
        artists
      }) {
        const results = this.fetchingMore
          ? [...this.results, ...artists]
          : artists;
        this.fetchingMore = false;
        return { results, total: count };
      },
      skip() {
        return this.searchDisabled;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  max-height: 80%;
  height: fit-content;
  margin: 0 1rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h4 {
    color: $white;
    margin-bottom: 32px;
  }
  .existing {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .inputs {
      width: 80%;
      .form-group {
        input {
          @include details-light();
          color: $white;
          width: 100%;
        }
      }
    }
    .switch-type {
      margin: 1rem 0;
      font-size: 0.9rem;
      color: $accent;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .search-title {
      font-size: 0.9rem;
      margin: 0.3rem;
      color: $text-additional;
      width: 80%;
    }
    .results-container {
      color: $white;
      width: 80%;
      max-height: 30vh;
      overflow-y: auto;
      padding-right: 0.5rem;
      margin-bottom: 1rem;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $accent;
        border-radius: 5px;
      }
      .results {
        .result {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          background-color: $gray-1;
          padding: 0.5rem;
          border-radius: 7.5px;
          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            font-family: sans-serif;
            font-size: 0.9rem;
            border-radius: 50%;
            background-color: $accent;
            color: $white;
            margin-right: 0.5rem;
          }
          .details {
            display: flex;
            flex-direction: column;
            span {
              font-size: 0.9rem;
            }
          }
          &:hover {
            cursor: pointer;
            background-color: #3d3d3d;
          }
        }
      }
      .searching,
      .fetch-more,
      .no-results {
        margin-bottom: 0.5rem;
        background-color: $gray-1;
        padding: 0.7rem;
        border-radius: 5px;
        font-size: 0.8rem;
        text-align: center;
        &:hover {
          cursor: pointer;
          background-color: #3d3d3d;
        }
      }
    }
  }
  .adding-new {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .inputs {
      width: 80%;
      .form-group {
        margin-bottom: 1rem;
        input {
          @include details-light();
          color: $white;
          width: 100%;
        }
      }
      .names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          width: 48%;
        }
      }
    }
    .switch-type {
      margin-bottom: 1rem;
      font-size: 0.9rem;
      color: $accent;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
