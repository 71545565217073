<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.subscription.loading"
          color="#FF035A"
          size="50"
        />
        <div class="modal-container" v-else>
          <div class="modal-header">
            <slot name="header">
              <div @click="!submitting ? $emit('close') : 0" class="icon">
                <ModalCloseIcon :dark="true" />
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <h4>ADD A COLLABORATOR</h4>
              <div class="existing" v-if="!addingNew">
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="collaborator.name"
                      type="text"
                      class="text-input dark"
                      placeholder="Search Contacts..."
                      autocomplete="no"
                    />
                  </div>
                </div>
                <!--
                <div class="search-title" v-if="resultsAvailable">
                  <b>Previous Collaborators </b>
                  <i>(Showing {{ results.length }} of {{ total }})</i>
                </div>
                -->
                <div class="results-container" v-if="!fieldsEmpty">
                  <div class="results" v-if="resultsAvailable">
                    <div
                      class="result"
                      v-for="(data, i) in results"
                      :key="i"
                      @click="selectResult(data)"
                    >
                      <img :src="data.avatar_url" v-if="data.avatar_url" />
                      <div class="initials" v-else>
                        {{ data.name | initials }}
                      </div>
                      <div class="details">
                        <span>{{ data.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="searching" v-if="searching">
                    Searching...
                  </div>
                  <div class="fetch-more" v-if="canFetchMore" @click="loadMore">
                    + Load More Results
                  </div>
                  <div class="no-results" v-if="total === 0">
                    No Search Results found
                  </div>
                </div>
                <span class="switch-type" @click="switchType">
                  Or Invite the Artist/Producer to Nvoko
                </span>
              </div>
              <div class="adding-new" v-else>
                <div class="inputs">
                  <div class="form-group">
                    <input
                      v-model="collaborator.user.email"
                      id="email"
                      type="email"
                      class="text-input dark"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <span class="switch-type" @click="switchType">
                  Search Artists/Producers on Nvoko instead
                </span>
              </div>
              <div class="is-host-container" v-if="updateHostApplicable">
                <label class="is-host"
                  >Set Artist as the Host
                  <input
                    type="checkbox"
                    name="isHost"
                    id="isHost"
                    v-model="isHost"
                  />
                  <span class="checkmark"></span>
                </label>
              </div>
              <div class="buttons">
                <button
                  class="primary outline dark"
                  :disabled="submitting"
                  @click="$emit('close')"
                >
                  Cancel
                </button>
                <button
                  class="primary"
                  @click="addCollaborator"
                  :disabled="!valid || submitting"
                >
                  {{
                    !submitting
                      ? !addingNew
                        ? "Add"
                        : "Invite"
                      : "Submitting..."
                  }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import SEARCH_ARTISTS from "@/api/queries/SEARCH_ARTISTS.gql";
import ADD_COLLABORATOR from "@/api/mutations/ADD_COLLABORATOR.gql";
import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_SUBSCRIPTION from "@/api/queries/GET_SUBSCRIPTION.gql";

const initialState = addingNew => ({
  collaborator: {},
  offset: 0,
  results: [],
  total: null,
  fetchingMore: false,
  selected: {},
  addingNew: !!addingNew,
  submitting: false,
  isHost: false
});

export default {
  name: "AddCollaborator",
  components: {
    ModalCloseIcon,
    Loader
  },
  props: {
    song: {
      type: Object,
      required: true
    }
  },
  data() {
    return initialState();
  },
  watch: {
    searchData(data) {
      this.results = data.results;
      this.total = data.total;
    },
    fields() {
      if (this.searchDisabled) return;
      if (this.collaborator.id) delete this.collaborator.id;
    },
    addingNew(val) {
      this.collaborator = val ? { user: {} } : {};
    }
  },
  computed: {
    valid() {
      const { id, name, user = {} } = this.collaborator;
      if (id && name) {
        return !this.song.collaborators.find(el => el.info.id === id);
      }
      if (user.email && this.addingNew) return true;
      return false;
    },
    fields() {
      return JSON.stringify(this.collaborator);
    },
    fieldsEmpty() {
      return !this.collaborator.name;
    },
    searchDisabled() {
      const { fieldsEmpty, isSelectionActive, addingNew } = this;
      return fieldsEmpty || isSelectionActive || addingNew;
    },
    searching() {
      return this.$apollo.queries.searchData.loading;
    },
    resultsAvailable() {
      return (
        this.results &&
        this.results.length &&
        (!this.searching || this.fetchingMore) &&
        !this.fieldsEmpty &&
        !this.searchDisabled
      );
    },
    canFetchMore() {
      const { results, resultsAvailable, total, searching } = this;
      return resultsAvailable && results.length < total && !searching;
    },
    isSelectionActive() {
      return JSON.stringify(this.collaborator) === this.selected;
    },
    updateHostApplicable() {
      return this.song.collaborators.length === 1;
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    switchType() {
      Object.assign(this.$data, initialState(!this.addingNew));
    },
    loadMore() {
      this.offset += 10;
      this.fetchingMore = true;
    },
    selectResult(data) {
      const { id, name } = data;
      const artist = { id, name };
      this.selected = JSON.stringify(artist);
      this.collaborator = artist;
    },
    formatSplit() {
      const {
        collaborator,
        song: { id, collaborators, host_id },
        isHost
      } = this;

      const proposer = collaborators.find(el => el.isMe).info.id;
      const split = {
        song_id: id,
        artist_id_proposer: proposer
      };

      const details = collaborators.flatMap(el => {
        if (el.isHost) return [];
        return [
          {
            deal_id: el.deal_id,
            percentage: el.percentage,
            is_host: false,
            accepted: el.info.id === proposer
          }
        ];
      });

      let newDetail = {
        accepted: false,
        deal: { data: { song_id: id } },
        percentage: {
          recording: 0,
          publishing: 0,
          music_videos: 0
        }
      };

      newDetail.deal.data[
        !isHost ? "artist_id_from" : "artist_id_to"
      ] = host_id;

      const artist = collaborator.id
        ? { id: collaborator.id }
        : {
            artist_users: {
              data: {
                user_id_inviter: this.$store.getters["account/getUserId"],
                role: "artist",
                access: "owner",
                user: {
                  data: {
                    ...collaborator.user,
                    status: "invited"
                  }
                }
              }
            },
            artist_preferences: { data: {} }
          };

      artist.connectionsToMe = {
        data: { artist_id_from: host_id },
        on_conflict: {
          constraint: "artist_connections_artist_id_from_artist_id_to_key",
          update_columns: "artist_id_from"
        }
      };

      if (isHost)
        artist.songs = {
          data: { id },
          on_conflict: {
            constraint: "songs_pkey",
            update_columns: "host_id"
          }
        };

      if (!artist.id) {
        newDetail.deal.data[isHost ? "artistFrom" : "artistTo"] = {
          data: artist
        };
      } else {
        newDetail.deal.data[isHost ? "artist_id_from" : "artist_id_to"] =
          artist.id;
      }

      return {
        ...split,
        split_details: { data: [...details, newDetail] }
      };
    },
    getConnections() {
      const {
        collaborator: { id },
        song: { host_id }
      } = this;
      return !id ? [] : [{ artist_id_from: host_id, artist_id_to: id }];
    },
    addCollaborator() {
      this.submitting = true;
      const split = this.formatSplit();
      const connections = this.getConnections();
      this.$apollo.mutate({
        mutation: ADD_COLLABORATOR,
        variables: { split, connections },
        update: (store, { data: { insert_splits_one } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const index = data.songs.findIndex(el => el.id === this.song.id);
          data.songs[index] = insert_splits_one.song;

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = false;
          this.$emit("close");
        }
      });
    }
  },
  apollo: {
    searchData: {
      query: SEARCH_ARTISTS,
      variables() {
        const {
          subscription: { status },
          collaborator: { name },
          offset
        } = this;

        const where = {
          _and: [{ name: { _ilike: `%${name || ""}%` } }]
        };

        if (status !== "active")
          where._and.push({
            artist_users: {
              user: { subscriptions: { status: { _eq: "active" } } }
            }
          });

        return { offset, limit: 10, where };
      },
      update({
        artists_aggregate: {
          aggregate: { count }
        },
        artists
      }) {
        const results = this.fetchingMore
          ? [...this.results, ...artists]
          : artists;
        this.fetchingMore = false;
        return { results, total: count };
      },
      skip() {
        return this.searchDisabled && this.subscription;
      }
    },
    subscription: {
      query: GET_SUBSCRIPTION,
      update: ({ stripe_get_subscription }) => stripe_get_subscription
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";

.modal-container {
  max-width: 600px;
  max-height: 80%;
  height: fit-content;
  margin: 0 1rem;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 32px 32px 0;
}

.modal-body {
  padding: 0 34px 34px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  h4 {
    color: $white;
    margin-bottom: 32px;
  }
  .existing {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .inputs {
      width: 80%;
      .form-group {
        input {
          @include details-light();
          color: $white;
          width: 100%;
        }
      }
    }
    .switch-type {
      margin: 1rem 0;
      font-size: 0.9rem;
      color: $accent;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .search-title {
      font-size: 0.9rem;
      margin: 0.3rem;
      color: $text-additional;
      width: 80%;
    }
    .results-container {
      color: $white;
      width: 80%;
      max-height: 30vh;
      overflow-y: auto;
      padding-right: 0.5rem;
      margin-bottom: 1rem;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        @include background-opacity(#cdcccc, 0.3);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $accent;
        border-radius: 5px;
      }
      .results {
        .result {
          display: flex;
          align-items: center;
          margin: 0.5rem;
          background-color: $gray-1;
          padding: 0.5rem;
          border-radius: 7.5px;
          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 0.5rem;
            border: 1px $accent solid;
          }
          .initials {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            font-family: sans-serif;
            font-size: 0.9rem;
            border-radius: 50%;
            background-color: $black;
            border: 1px solid $accent;
            color: $white;
            margin-right: 0.5rem;
          }
          .details {
            display: flex;
            flex-direction: column;
            span {
              font-size: 0.9rem;
            }
          }
          &:hover {
            cursor: pointer;
            background-color: #3d3d3d;
          }
        }
      }
      .searching,
      .fetch-more,
      .no-results {
        margin: 0.5rem;
        background-color: $gray-1;
        padding: 0.7rem;
        border-radius: 5px;
        font-size: 0.8rem;
        text-align: center;
        &:hover {
          cursor: pointer;
          background-color: #3d3d3d;
        }
      }
    }
  }
  .adding-new {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .inputs {
      width: 80%;
      .form-group {
        margin-bottom: 1rem;
        input {
          @include details-light();
          color: $white;
          width: 100%;
        }
      }
      .names {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          width: 48%;
        }
      }
    }
    .switch-type {
      margin-bottom: 1rem;
      font-size: 0.9rem;
      color: $accent;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .is-host-container {
    width: 100%;
    padding: 2% 12%;
    .is-host {
      color: #fff;
      padding: 0 10%;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 17px;
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: #ff035a;
          &:after {
            display: block;
          }
        }
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border-radius: 5px;
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 10px;
          top: 6px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
      &:hover {
        input {
          & ~ .checkmark {
            background-color: #ccc;
          }
          &:checked ~ .checkmark {
            background-color: #ff3e82;
          }
        }
      }
    }
  }
  .buttons {
    margin-top: 1rem;
    display: flex;
    button {
      margin: 0 0.5rem;
      width: 136px;
      height: 44px;
    }
  }
}
</style>
