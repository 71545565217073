<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract_question.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <span class="progress" v-if="applicableQuestions.length > 1">
                {{ `Step ${step}` }}
              </span>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <DealTerm v-model="currentQuestion" :parties="parties" />
              <div class="buttons">
                <button v-if="step > 1" @click="step--" class="primary outline">
                  Previous
                </button>
                <button
                  v-if="step < applicableQuestions.length"
                  :disabled="isInvalid"
                  @click="step++"
                  class="primary"
                >
                  Next
                </button>
                <button
                  v-if="
                    applicableQuestions.length === 1 ||
                      step === applicableQuestions.length
                  "
                  :disabled="isInvalid || submitting"
                  @click="updateTerms"
                  class="primary"
                >
                  {{ submitting ? "Submitting..." : "Update" }}
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DealTerm from "@/components/DealTerm.vue";
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import GET_CONTRACT_QUESTIONS from "@/api/queries/GET_CONTRACT_QUESTIONS.gql";
import UPDATE_CONTRACT_TERMS from "@/api/mutations/UPDATE_CONTRACT_TERMS.gql";

export default {
  name: "UpdateDealTerm",
  components: {
    ModalCloseIcon,
    Loader,
    DealTerm
  },
  data() {
    return {
      contract_id: null,
      question: {},
      questions: [],
      answered: [],
      applicableQuestions: [],
      submitting: false,
      step: 1,
      splitQuestionsBool: [
        "18919b87-bbfb-4647-bdc1-39f67f30d8ec",
        "0215a342-06f2-4a8e-9247-f04a775880de",
        "8bb2d9a8-f4ac-47d4-b9f6-49477645171f"
      ],
      splitQuestions: [
        "0144d576-fa84-4179-99c2-e28bd69000dc",
        "5dfe5592-5903-4bec-9f29-9022d1ed92f8",
        "06b6c5c5-2814-4df0-bf27-e54010740293"
      ]
    };
  },
  props: {
    deal: Object,
    updateQuestion: String
  },
  watch: {
    contract_question(question) {
      this.question = question;
      this.updateApplicableQuestions();
    },
    applicableQuestions: {
      handler() {
        this.updateApplicableQuestions();
      },
      deep: true
    }
  },
  computed: {
    parties() {
      const { artist, myArtist, party } = this.deal;
      const isHost = party === "host";
      return {
        host: isHost ? myArtist.name : artist.name,
        guest: isHost ? artist.name : myArtist.name
      };
    },
    isInvalid() {
      const { isAnswerValid, currentQuestion } = this;
      return !isAnswerValid(currentQuestion);
    },
    currentQuestion() {
      return this.applicableQuestions[this.step - 1] || {};
    },
    currentAnswers() {
      let answers = {};
      this.questions.forEach(({ id, type }) => {
        answers[id] = type === "multiple_choice_multiple" ? [] : undefined;
      });
      this.answered.forEach(
        ({ answer: { value }, contract_question: { id } }) => {
          answers[id] = value;
        }
      );
      this.applicableQuestions.forEach(({ id, type, answer }) => {
        answers[id] =
          type !== "multiple_choice_multiple"
            ? answer.value
            : answer.map(el => el.value);

        const index = this.splitQuestions.findIndex(el => el === id);

        if (index > -1) {
          answers[this.splitQuestionsBool[index]] = answer.value > 0;
        }
      });
      return answers;
    }
  },
  methods: {
    fillPartyNames(question) {
      const resp = question;
      const { host, guest } = this.parties;
      const hostFilled = question.title.replace(new RegExp("host", "ig"), host);
      resp.title = hostFilled.replace(new RegExp("guest", "ig"), guest);
      return resp;
    },

    formatQuestion(question, questions = this.questions) {
      const { fillPartyNames } = this;
      const addSubs = question => {
        if (!question.choices)
          return { ...fillPartyNames(question), answer: {} };

        const choices = question.choices.map(choice => {
          let sub;
          if (choice.sub_id) {
            sub = questions.find(el => el.id === choice.sub_id);
            if (sub)
              sub = {
                ...addSubs(sub),
                answer: sub.type === "multiple_choice_multiple" ? [] : {}
              };
          }
          return { ...choice, sub };
        });

        return {
          ...fillPartyNames(question),
          choices,
          answer: question.type === "multiple_choice_multiple" ? [] : {}
        };
      };

      return addSubs(question);
    },

    updateApplicableQuestions() {
      const {
        question,
        questions,
        runChecks,
        formatQuestion,
        applicableQuestions,
        currentAnswers,
        splitQuestionsBool
      } = this;

      if (!applicableQuestions.length)
        return (this.applicableQuestions = [question]);

      const updatedQuestions = [
        question,
        ...applicableQuestions.flatMap(el =>
          runChecks(el.conditions.checks) && el.id !== question.id ? [el] : []
        ),
        ...questions.flatMap(el => {
          const alreadyAdded = applicableQuestions.find(q => q.id === el.id);
          const toBeSkipped = splitQuestionsBool.includes(el.id);
          const notAnswered = ["[]", undefined].includes(
            JSON.stringify(currentAnswers[el.id])
          );

          return !el.parent_id &&
            notAnswered &&
            !alreadyAdded &&
            !toBeSkipped &&
            runChecks(el.conditions.checks)
            ? [formatQuestion(el)]
            : [];
        })
      ];

      if (applicableQuestions.length !== updatedQuestions.length)
        this.applicableQuestions = updatedQuestions;
    },

    getQuestion(questions) {
      const question = questions.find(
        question => question.id === this.updateQuestion
      );
      return this.formatQuestion(question, questions);
    },

    isAnswerValid({ answer = {}, type, id }) {
      const { isAnswerValid, splitQuestions, currentAnswers } = this;

      if (splitQuestions.includes(id) && Number(answer.value) > 100)
        return false;

      if (
        id === "78b2ee02-5358-4df5-a1b2-3467b204ecc8" &&
        !answer.find(el => el.value === "producing")
      )
        return false;

      if (
        type === "multiple_choice_multiple" &&
        answer.length &&
        answer.every(el => !!el.value)
      )
        return true;
      if (
        ["null", "undefined"].includes(typeof answer.value) ||
        (type !== "multiple_choice" && !answer.value)
      )
        return false;

      if (
        id === "6c31c155-c6d2-490b-9125-34fb856a8724" &&
        !answer.value &&
        !currentAnswers["18919b87-bbfb-4647-bdc1-39f67f30d8ec"]
      )
        return false;

      if (
        id === "0144d576-fa84-4179-99c2-e28bd69000dc" &&
        !currentAnswers["18919b87-bbfb-4647-bdc1-39f67f30d8ec"] &&
        !currentAnswers["6c31c155-c6d2-490b-9125-34fb856a8724"]
      )
        return false;

      if (answer.sub && !isAnswerValid(answer.sub)) return false;
      return true;
    },

    runChecks(checks, isCheckingDeleted) {
      const { currentAnswers } = this;

      const compare = ({ question_id, value, _or, _and }) => {
        if (_or) return _or.some(el => compare(el));
        if (_and) return _and.every(el => compare(el));
        switch (Object.keys(value)[0]) {
          case "_eq":
            return currentAnswers[question_id] === value._eq;
          case "_contains":
            return currentAnswers[question_id].includes(value._contains);
          default:
            return true;
        }
      };

      if (!checks) return false;

      const checkResult = checks.every(el => compare(el));

      return isCheckingDeleted ? !checkResult : checkResult;
    },

    parseAnswers() {
      const {
        questions,
        contract_id,
        applicableQuestions,
        runChecks,
        splitQuestionsBool,
        splitQuestions
      } = this;

      const getAnswers = ({ id, type, answer }) => {
        let response = [
          {
            contract_id,
            question_id: id,
            answer: {
              value:
                type !== "multiple_choice_multiple"
                  ? answer.value
                  : answer.map(el => el.value)
            }
          }
        ];

        const index = splitQuestions.findIndex(el => el === id);

        if (index > -1) {
          response.push({
            contract_id,
            question_id: splitQuestionsBool[index],
            answer: { value: answer.value > 0 }
          });
        }

        if (answer.sub) response.push.apply(response, getAnswers(answer.sub));
        return response;
      };

      const updated = applicableQuestions.flatMap(el => getAnswers(el));

      const deleted = {
        _or: questions.flatMap(el => {
          console.log(el);
          const isDeleted = runChecks(el.conditions.checks, true);
          if (isDeleted) {
            this.currentAnswers[el.id] =
              el.type === "multiple_choice_multiple" ? [] : undefined;
          }

          return isDeleted
            ? [
                {
                  contract_id: { _eq: contract_id },
                  question_id: { _eq: el.id }
                }
              ]
            : [];
        })
      };

      return { updated, deleted };
    },

    calculateNewSplit(fromID, deal_id, song_id, split, answers) {
      const { splitQuestions } = this;

      let newSplit = split;

      let index = newSplit.findIndex(el => el.deal_id === deal_id);
      let newPercentage = newSplit[index].percentage;

      answers.forEach(({ question_id, answer: { value } }) => {
        if (splitQuestions.includes(question_id)) {
          newPercentage[
            ["recording", "publishing", "music_videos"][
              splitQuestions.findIndex(el => el === question_id)
            ]
          ] = Number(value);
        }
      });

      newSplit[index].percentage = newPercentage;

      return {
        song_id,
        artist_id_proposer: fromID,
        split_details: { data: newSplit }
      };
    },

    getDealUpdates(answers, party) {
      let resp = { status: `deal-updated-${party}` };
      const services = "58c27cc4-b0eb-4ab0-b1ad-2fd7b7ca1b1a";
      const involvesFee = "6c31c155-c6d2-490b-9125-34fb856a8724";
      const fee = "5517025e-282e-4ead-bcad-224a65f9ca05";
      answers.forEach(({ question_id, answer: { value } }) => {
        if (question_id === services) resp.services = value;
        if (question_id === fee) resp.fee = parseFloat(value);
        if (question_id === involvesFee) {
          if (value) resp.fee_status = "pending";
          else {
            resp.fee_status = null;
            resp.fee = 0;
          }
        }
      });
      return resp;
    },

    updateTerms() {
      this.submitting = true;

      const {
        parseAnswers,
        calculateNewSplit,
        getDealUpdates,
        deal: {
          party,
          split: oldSplit,
          song_id,
          id: deal_id,
          artist: { id: toID },
          myArtist: { id: fromID, name: myArtistName }
        }
      } = this;

      const { updated, deleted } = parseAnswers();

      const split = calculateNewSplit(
        fromID,
        deal_id,
        song_id,
        oldSplit,
        updated
      );

      const dealUpdates = getDealUpdates(updated, party);

      const message = {
        deal_id,
        artist_id_to: toID,
        artist_id_from: fromID,
        system_message: {
          recipient: `${myArtistName} made changes to the deal terms`,
          sender: "You made changes to the deal terms"
        }
      };

      this.$apollo.mutate({
        mutation: UPDATE_CONTRACT_TERMS,
        variables: { deal_id, dealUpdates, updated, deleted, message, split },
        update: (
          store,
          {
            data: {
              insert_contract_answers,
              delete_contract_answers,
              insert_splits_one
            }
          }
        ) => {
          if (insert_contract_answers && delete_contract_answers) {
            const data = store.readQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              }
            });

            const index = data.songs.findIndex(el => el.id === song_id);
            data.songs[index] = {
              ...data.songs[index],
              splits: [insert_splits_one]
            };

            store.writeQuery({
              query: GET_SONGS,
              variables: {
                artistId: this.$store.getters["account/getArtistId"]
              },
              data
            });
            this.submitting = false;
            this.$emit("close", true);
          }
        }
      });
    }
  },
  apollo: {
    contract_question: {
      query: GET_CONTRACT_QUESTIONS,
      variables() {
        const {
          deal: { id: deal_id }
        } = this;
        return { deal_id };
      },
      update({ contracts, contract_questions, contract_answers }) {
        if (contracts.length) this.contract_id = contracts[0].id;
        this.questions = contract_questions;
        this.answered = contract_answers;
        return this.getQuestion(contract_questions);
      },
      fetchPolicy: "network-only"
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
.modal-container {
  width: 916px;
  height: 719px;
  max-height: calc(100vh - 80px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .progress {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 1px;
    color: $accent;
  }
}

.modal-body {
  padding: 10px 80px;
  padding-top: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: $accent rgba(#cdcccc, 0.3);
  &::-webkit-scrollbar {
    /* width of the entire scrollbar */
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    /* color of the tracking area */
    @include background-opacity(#cdcccc, 0.3);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    /* color of the scroll thumb */
    background-color: $accent;
    /* roundness of the scroll thumb */
    border-radius: 5px;
  }
  .buttons {
    height: 5rem;
    padding: 1.5rem 0;
    display: flex;
    justify-content: flex-end;
    border-top: solid #f0f0f0 0.5px;
    button {
      margin: 0 5px;
      width: 169px;
      height: 50px;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
</style>
