<template>
  <div class="right-panel">
    <div v-if="!Object.keys(song || {}).length" />
    <div v-else class="panel-container">
      <div class="panel-header">
        {{ song.name }}
      </div>
      <div class="collaborators">
        <div
          class="card company"
          v-if="companyOptions.applicable && song.collaborators.length > 1"
        >
          <div class="step">
            <span class="question">
              Who would be entering into this agreement?
            </span>
            <div class="inputs">
              <div class="radiobuttons">
                <div
                  class="radio-group"
                  v-for="(choice, i) in companyQuestion.choices"
                  :key="i"
                >
                  <label class="radio">
                    <input
                      type="radio"
                      :value="choice"
                      v-model="companyQuestion.answer"
                    />
                    <span class="text">
                      {{ choice.label }}
                      <template v-if="choice.value">
                        <br />
                        + <br />
                        {{ artist.name }}
                      </template>
                    </span>
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            class="primary outline"
            :disabled="submitting === song.id"
            @click="updateCompanyData"
          >
            {{ submitting ? "Submitting" : "Continue" }}
          </button>
        </div>
        <template
          v-if="!companyOptions.applicable || song.collaborators.length === 1"
        >
          <div
            class="card"
            v-for="({
              info,
              services,
              percentage = {},
              isMe,
              songHost,
              hostActive,
              status,
              deal_id,
              fee_status,
              inducement,
              category
              // chargesEnabled,
              // fee
            },
            i) in song.collaborators"
            :key="i"
          >
            <div class="top">
              <div class="artist">
                <img v-if="info.avatar_url" :src="info.avatar_url" />
                <div v-else class="initials">
                  {{ info.name | initials }}
                </div>
                <div class="details">
                  <span class="name">{{ info.name }}</span>
                  <span class="contribution" v-if="!songHost">
                    {{ services | contribution }}
                  </span>
                </div>
              </div>
              <div class="party-icon" v-if="!i">HOST</div>
              <div class="check" v-else-if="category === 'completed'">
                <img src="../assets/icons/Check.svg" width="16" height="16" />
              </div>
              <div class="attention" v-else-if="category === 'action-needed'">
                <img
                  src="../assets/icons/Attention.svg"
                  width="16"
                  height="16"
                />
              </div>
            </div>
            <div class="split" v-if="isMe || song.isHost">
              <div>
                <span class="type">Recording</span>
                <span
                  class="percentage"
                  :class="{
                    invalid:
                      percentage.recording > 100 || percentage.recording < 0
                  }"
                >
                  {{ percentage.recording + "%" }}
                </span>
              </div>
              <div>
                <span class="type">Publishing</span>
                <span
                  class="percentage"
                  :class="{
                    invalid:
                      percentage.publishing > 100 || percentage.publishing < 0
                  }"
                >
                  {{ percentage.publishing + "%" }}
                </span>
              </div>
            </div>
            <div class="buttons" v-if="!songHost">
              <button
                class="primary"
                @click="openModal('create', deal_id, status, info, hostActive)"
                v-if="status === 'pending'"
              >
                Create Deal
              </button>
              <button
                class="primary"
                @click="openModal('review', deal_id, status, info, hostActive)"
                v-if="
                  (isMe || song.isHost) &&
                    (status.includes('deal-updated-') ||
                      status.includes('contract-signed-'))
                "
              >
                Review Deal
              </button>
              <button
                class="primary"
                @click="openModal('view', deal_id, status, info, hostActive)"
                v-if="(isMe || song.isHost) && status === 'contract-ready'"
              >
                View Contract
              </button>
              <button
                class="primary outline"
                @click="
                  openModal(
                    'inducement',
                    deal_id,
                    status,
                    info,
                    hostActive,
                    inducement
                  )
                "
                v-if="status === 'contract-ready' && inducement.applicable"
              >
                {{ inducement.signature ? "View " : "Sign " }} Inducement
                Contract
              </button>
              <!--
              <button
                class="primary outline"
                @click="openModal('view', deal_id, status, info, song.isHost)"
                v-if="status === 'contract-ready'"
              >
                Print Contract
              </button>
              <button
                class="primary"
                @click="
                  openModal('fund', deal_id, status, info, song.isHost, fee)
                "
                :disabled="!chargesEnabled"
                :title="
                  !chargesEnabled ? 'This user has not enabled charges yet' : ''
                "
                v-if="
                  song.isHost &&
                    status === 'contract-ready' &&
                    fee_status === 'pending'
                "
              >
                Fund Deal
              </button>
              -->
              <button
                class="primary"
                @click="releaseFunds(deal_id, song.id)"
                v-if="
                  hostActive &&
                    status === 'contract-ready' &&
                    ['requires_capture', 'processing'].includes(fee_status)
                "
                :disabled="
                  fee_status === 'processing' || submitting === deal_id
                "
              >
                {{ submitting === deal_id ? "Submitting..." : "Release Funds" }}
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="add-collaborator" v-if="song.isHost">
        <button class="primary" @click="addingCollaborator = true">
          <img src="../assets/icons/User.svg" />
          Add Collaborator
        </button>
      </div>
    </div>
    <AddCollaborator
      v-if="addingCollaborator"
      @close="addingCollaborator = false"
      :song="song"
    />
    <CreateDeal
      v-if="modalName === 'create-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ReviewDeal
      v-if="modalName === 'review-deal'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ViewContract
      v-if="modalName === 'view-contract'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <ProjectFunding
      v-if="modalName === 'project-funding'"
      @close="clearDeal"
      :deal="currentDeal"
    />
    <InducementContract
      v-if="modalName === 'inducement-contract'"
      @close="clearDeal"
      :deal="currentDeal"
      :inducement="inducement"
    />
  </div>
</template>

<script>
import AddCollaborator from "@/components/modals/AddCollaborator";
import CreateDeal from "@/components/modals/CreateDeal";
import ReviewDeal from "@/components/modals/ReviewDeal";
import ViewContract from "@/components/modals/ViewContract";
import ProjectFunding from "@/components/modals/ProjectFunding";
import InducementContract from "@/components/modals/InducementContract";

import GET_SONGS from "@/api/queries/GET_SONGS.gql";
import RELEASE_FUNDS from "@/api/mutations/RELEASE_FUNDS.gql";
import UPDATE_SONG from "@/api/mutations/UPDATE_SONG.gql";
import UPDATE_DEAL from "@/api/mutations/UPDATE_DEAL.gql";

export default {
  name: "SongPreview",
  components: {
    AddCollaborator,
    CreateDeal,
    ReviewDeal,
    ViewContract,
    ProjectFunding,
    InducementContract
  },
  props: {
    songs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addingCollaborator: false,
      currentDeal: null,
      inducement: null,
      modalName: "",
      submitting: "",
      companyQuestion: {
        answer: {},
        choices: []
      }
    };
  },
  mounted() {
    this.updateCompanyQuestion();
  },
  watch: {
    "song.id"() {
      this.updateCompanyQuestion();
    }
  },
  computed: {
    song() {
      const { song } = this.$route.query;
      if (!song) return {};
      return this.songs.find(el => el.id === song);
    },
    artist() {
      return this.$store.getters["account/getArtist"];
    },
    companyOptions() {
      const applicable = typeof this.song.isPartyCompany !== "boolean";
      const valid = typeof this.companyQuestion.answer.value === "string";
      return { applicable, valid };
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    },
    contribution(data) {
      return data
        .map(el => el.charAt(0).toUpperCase() + el.slice(1))
        .join(", ");
    }
  },
  methods: {
    openModal(type, deal_id, status, artist, hostActive, inducement, fee) {
      const { collaborators } = this.song;
      const songHost = collaborators[0].info;
      const songHostActive =
        songHost.id === this.$store.getters["account/getArtistId"];
      this.currentDeal = {
        myArtist: songHostActive ? songHost : artist,
        artist: songHostActive ? artist : songHost,
        party: hostActive ? "host" : "guest",
        song_id: this.song.id,
        purchased: this.song.purchased,
        id: deal_id,
        status,
        fee,
        split: collaborators.flatMap(({ deal_id, percentage }) =>
          deal_id ? [{ deal_id, percentage }] : []
        )
      };

      switch (type) {
        case "create":
          this.modalName = "create-deal";
          break;
        case "review":
          this.modalName = "review-deal";
          break;
        case "view":
          this.modalName = "view-contract";
          break;
        case "fund":
          this.modalName = "project-funding";
          break;
        case "inducement":
          this.inducement = inducement;
          this.modalName = "inducement-contract";
          break;
        default:
          break;
      }
    },
    clearDeal() {
      this.currentDeal = null;
      this.inducement = null;
      this.modalName = "";
    },
    updateCompanyQuestion() {
      const choices = this.artist.company_artists.flatMap(({ company }) => {
        if (!company) return [];
        return [{ value: company.id, label: company.name }];
      });

      choices.unshift({ value: "", label: this.artist.name });

      this.$set(this.companyQuestion, "choices", choices);
      this.$set(this.companyQuestion, "answer", {});
    },
    updateCompanyData() {
      const {
        song: { isHost },
        updateHostCompanyData,
        updateGuestCompanyData
      } = this;

      return isHost ? updateHostCompanyData() : updateGuestCompanyData();
    },
    updateHostCompanyData() {
      const {
        song: { id },
        companyQuestion: {
          answer: { value }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value) update = { is_host_company: false };
      else update = { is_host_company: true, host_company_id: value };

      this.$apollo.mutate({
        mutation: UPDATE_SONG,
        variables: { id, update },
        update: (store, { data: { update_songs_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          data.songs[song] = { ...data.songs[song], ...update_songs_by_pk };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    },
    updateGuestCompanyData() {
      const {
        song: { id, myDeal },
        companyQuestion: {
          answer: { value }
        }
      } = this;

      this.submitting = id;
      let update = {};

      if (!value) update = { is_guest_company: false };
      else update = { is_guest_company: true, guest_company_id: value };

      this.$apollo.mutate({
        mutation: UPDATE_DEAL,
        variables: { id: myDeal, update },
        update: (store, { data: { update_deals_by_pk } }) => {
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === myDeal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            ...update_deals_by_pk
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    },
    releaseFunds(deal, song_id) {
      this.submitting = deal;

      this.$apollo.mutate({
        mutation: RELEASE_FUNDS,
        variables: { deal },
        update: (store, { data: { stripe_capture_funds } }) => {
          if (!stripe_capture_funds.success) return;
          const data = store.readQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            }
          });

          const song = data.songs.findIndex(el => el.id === song_id);
          const index = data.songs[song].splits[0].split_details.findIndex(
            el => el.deal_id === deal
          );

          data.songs[song].splits[0].split_details[index].deal = {
            ...data.songs[song].splits[0].split_details[index].deal,
            fee_status: "succeeded"
          };

          store.writeQuery({
            query: GET_SONGS,
            variables: {
              artistId: this.$store.getters["account/getArtistId"]
            },
            data
          });

          this.submitting = "";
          this.$emit("close");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";

.right-panel {
  display: flex;
  height: calc(100vh - 55px);
  flex-direction: column;
  background: $bg;
  .panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.25rem 2.5rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    .panel-header {
      font-size: 1.7rem;
    }
    .collaborators {
      margin-top: 1.75rem;
      .card {
        padding: 16px 0;
        margin-bottom: 31px;
        border: 1px solid $gray;
        border-radius: 10px;
        background-color: $white;
        box-shadow: 0px 16px 24px rgb(59 59 59 / 8%);
        &.company {
          padding: 0 10px;
          display: flex;
          flex-direction: column;
          .step {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-top: 42px;
            .question {
              width: 100%;
              height: fit-content;
              font-weight: 500;
              font-size: 20px;
              line-height: 22px;
              text-align: center;
              letter-spacing: -1.025px;
              color: $text;
              margin-bottom: 24px;
            }
            .inputs {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              .radiobuttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .radio-group {
                  .radio {
                    width: 144px;
                    text-align: center;
                    padding: 10px;
                  }
                }
              }
            }
          }
          button {
            margin-top: -10px;
            margin-bottom: 18px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .top {
          display: flex;
          width: 100%;
          padding: 0 20px;
          .artist {
            display: flex;
            flex: 1;
            align-items: center;
            img {
              width: 55px;
              height: 55px;
              border-radius: 50%;
            }
            .initials {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 55px;
              width: 55px;
              font-family: sans-serif;
              font-size: 22px;
              border-radius: 50%;
              background-color: $black;
              border: 1px solid $accent;
              color: $white;
            }
            .details {
              margin-left: 1rem;
              font-size: 1.25rem;
              display: flex;
              flex-direction: column;
              .contribution {
                @include details-light();
              }
            }
          }
          .party-icon {
            background-color: black;
            color: white;
            font-size: 15px;
            height: 22px;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1px 4px 2px;
          }
          .check {
            img {
              -webkit-filter: invert(1);
              filter: invert(1);
            }
          }
        }
        .split {
          padding-top: 0.75rem;
          display: flex;
          div {
            margin: 0 20px;
            display: flex;
            flex-direction: column;
            .type {
              @include details-light();
            }
            .percentage {
              margin-top: 0.35rem;
              font-family: "Roboto Mono";
              font-size: 1.3rem;
            }
            .invalid {
              color: $accent;
            }
          }
        }
        .buttons {
          padding: 0.5rem 20px 0;
          display: flex;
          justify-content: flex-end;
          button {
            display: flex;
            margin-left: 5px;
            img {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
    .add-collaborator {
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        display: flex;
        padding: 0.75rem 2.5rem;
        img {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
</style>
