<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div @click="$emit('close')" class="icon">
              <ModalCloseIcon :accent="false" />
            </div>
          </div>
          <div class="modal-body">
            <div class="text" v-html="contract.generated_text" />
          </div>
          <div class="modal-footer">
            <div class="buttons">
              <button @click="$emit('close')" class="primary outline">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";

export default {
  name: "ReviewDeal",
  components: {
    ModalCloseIcon
  },
  props: {
    contract: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Srisakdi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

.modal-container {
  width: 755px;
  height: fit-content;
  max-height: calc(100vh - 40px);
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 16px;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

.modal-body {
  padding: 0 40px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .text {
    flex: 1;
    width: 100%;
    height: calc(100vh - 615px);
    overflow-y: scroll;
    margin-bottom: 1rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    &::v-deep {
      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 15px 5px;
      }
    }
  }
}

.modal-footer {
  padding: 0 40px 15px;
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    button {
      width: 136px;
      height: 44px;
      margin-left: 32px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>
