<template>
  <div class="step">
    <span class="question">
      {{ value.title }}
      <div class="comment" v-if="value.comment">
        {{ value.comment }}
      </div>
    </span>

    <div class="file" v-if="value.type === 'file'">
      <FileUpload v-model="value.answer.value" name="PDF file" />
    </div>
    <div v-else class="inputs">
      <div class="radiobuttons" v-if="value.type === 'multiple_choice'">
        <div class="radio-group" v-for="(choice, i) in value.choices" :key="i">
          <label class="radio">
            <input type="radio" :value="choice" v-model="value.answer" />
            <span class="text">{{ choice.label }}</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div
        class="checkboxbuttons"
        v-if="value.type === 'multiple_choice_multiple'"
      >
        <div
          class="checkbox-group"
          v-for="(choice, i) in value.choices"
          :key="i"
        >
          <label class="checkbox">
            <input type="checkbox" :value="choice" v-model="value.answer" />
            <span class="text">{{ choice.label }}</span>
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="form-group" v-if="value.type === 'number'">
        <div class="prepend" v-if="value.prepend">{{ value.prepend }}</div>
        <input
          v-model="value.answer.value"
          type="number"
          class="text-input"
          :class="{
            'with-prepend': value.prepend,
            'with-append': value.append
          }"
          placeholder="Type the number"
        />
        <div class="append" v-if="value.append">{{ value.append }}</div>
      </div>
      <div class="form-group" v-if="value.type === 'text'">
        <input
          v-model="value.answer.value"
          type="text"
          class="text-input"
          :placeholder="value.placeholder || 'Type your answer'"
        />
      </div>
      <DealTerm
        :parties="parties"
        v-if="value.answer && value.answer.sub"
        v-model="value.answer.sub"
      />
    </div>
    <div
      class="info"
      v-if="value.id === '0144d576-fa84-4179-99c2-e28bd69000dc'"
    >
      <p>
        If <b>{{ parties.host }}</b> is unsigned, {{ parties.guest }} will
        receive
        <b>{{ Number(value.answer.value) || "_" }}%</b>
        of the total recording royalty net receipts, but if the artist is signed
        to a label, {{ parties.guest }} will receive
        <b>{{ Number(value.answer.value) || "_" }}%</b>
        of the Host Artist’s share of recording royalties net receipts based off
        of their recording agreement.
      </p>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/components/FileUpload.vue";

export default {
  name: "DealTerm",
  components: { FileUpload },
  props: ["value", "parties"],
  updated() {
    if (
      this.value.id === "78b2ee02-5358-4df5-a1b2-3467b204ecc8" &&
      !this.value.answer.find(el => el.value === "producing")
    ) {
      this.value.answer.push(
        this.value.choices.find(el => el.value === "producing")
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.step {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 42px;
  .question {
    width: 100%;
    height: fit-content;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -1.025px;
    color: $text;
    margin-bottom: 24px;
  }
  .file {
    margin: 1rem 20%;
    width: 60%;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .radiobuttons,
    .checkboxbuttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .radio-group,
      .checkbox-group {
        .radio,
        .checkbox {
          width: 144px;
          text-align: center;
          padding: 10px;
        }
      }
    }
    .form-group {
      width: 320px;
      display: flex;
      flex-direction: row;
      input {
        font-weight: 300;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.22px;
        color: $text-additional;
        flex: 1;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .with-prepend,
      .with-append {
        border-radius: 38px;
        border: 1px #ddd solid;
      }
      .with-prepend {
        padding-left: 15px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      .with-append {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .prepend,
      .append {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        padding: 0 17px;
        border: 1px #ddd solid;
      }
      .prepend {
        border-right: 0;
        border-top-left-radius: 38px;
        border-bottom-left-radius: 38px;
      }
      .append {
        border-left: 0;
        border-top-right-radius: 38px;
        border-bottom-right-radius: 38px;
      }
    }
  }
  .comment {
    text-align: center;
    margin: 0.1rem;
    font-style: italic;
    font-size: 1rem;
  }
  .info {
    margin: 1rem 4rem;
    text-align: center;
    p {
      margin: 1rem 0;
      color: $text-additional;
      font-weight: 200;
      b {
        color: $text;
      }
    }
  }
}
</style>
