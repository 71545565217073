<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <Loader
          v-if="$apollo.queries.contract.loading"
          color="#FF035A"
          size="50"
        />
        <div v-else class="modal-container">
          <div class="modal-header">
            <slot name="header">
              <div @click="$emit('close')" class="icon">
                <ModalCloseIcon :accent="false" />
              </div>
              <div class="title">
                <h3>View Contract</h3>
              </div>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <div class="details">
                <div class="text" v-html="contract.generated_text" />
                <div class="signatures">
                  <div>
                    <span
                      class="signature"
                      :style="{
                        fontFamily: `${host_signature.font}, cursive`
                      }"
                    >
                      {{ host_signature.name }}
                    </span>
                    <span class="party-name">Host</span>
                  </div>
                  <div>
                    <span
                      class="signature"
                      :style="{
                        fontFamily: `${guest_signature.font}, cursive`
                      }"
                    >
                      {{ guest_signature.name }}
                    </span>
                    <span class="party-name">Guest</span>
                  </div>
                </div>
                <div class=""></div>
              </div>
              <div class="buttons">
                <button @click="print" class="primary outline">
                  Download
                </button>
                <button @click="$emit('close')" class="primary outline">
                  Close
                </button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// import { jsPDF } from "jspdf";

import ModalCloseIcon from "@/assets/icons/Modal-Close.vue";
import Loader from "@/components/Loader.vue";

import GET_CONTRACT from "@/api/queries/GET_CONTRACT.gql";

export default {
  name: "ViewContract",
  components: {
    ModalCloseIcon,
    Loader
  },
  props: {
    deal: Object
  },
  computed: {
    host_signature() {
      const signature = this.contract.signatures.find(
        ({ party, type }) => party === "host" && type === "contract"
      );
      return signature ? signature.value : {};
    },
    guest_signature() {
      const signature = this.contract.signatures.find(
        ({ party, type }) => party === "guest" && type === "contract"
      );
      return signature ? signature.value : {};
    }
  },
  apollo: {
    contract: {
      query: GET_CONTRACT,
      variables() {
        return {
          deal_id: this.deal.id
        };
      },
      update: ({ contracts }) => contracts[0]
    }
  },
  methods: {
    print() {
      const html = this.contract.generated_text;
      const printWindow = window.open("", "", "height=400,width=800");
      printWindow.document.write("<html><head><title>Contract</title>");
      printWindow.document.write("</head><body >");
      printWindow.document.write(html);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/_mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Srisakdi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");

.modal-container {
  width: 816px;
  height: 95vh;
  background: #ffffff;
  box-shadow: -1px 0px 48px rgba(0, 0, 0, 0.0812937);
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1.5rem 32px;
  .icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .title {
    display: flex;
    width: 100%;
    justify-content: center;
    .avatar {
      width: 123px;
      height: 123px;
      overflow: hidden;
      border-radius: 15px;
      img {
        object-fit: cover;
      }
    }
    h2 {
      margin-left: 32px;
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      letter-spacing: -0.666667px;
      color: $text;
      span {
        font-weight: 700;
      }
    }
  }
}

.modal-body {
  width: 100%;
  height: calc(100% - 158px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 60px;
  padding-top: 0;
  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 1.5rem;
    scrollbar-width: thin;
    scrollbar-color: $accent rgba(#cdcccc, 0.3);
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      @include background-opacity(#cdcccc, 0.3);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $accent;
      border-radius: 5px;
    }
    .text {
      ::v-deep p {
        padding: 10px 0;
      }
      ::v-deep h1 {
        font-size: 2em;
      }
      ::v-deep h2 {
        font-size: 1.5em;
      }
    }
    .signatures {
      margin: 1rem;
      display: flex;
      justify-content: space-around;
      div {
        display: flex;
        flex-direction: column;
        .signature {
          font-size: 2rem;
        }
      }
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 40px;
    margin-bottom: -2.5rem;
    button {
      width: 136px;
      height: 44px;
      margin: 0 16px;
      font-weight: 300;
      padding: 0 8px;
    }
  }
}
</style>
