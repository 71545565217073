<template>
  <div class="container">
    <Loader v-if="$apollo.queries.deals.loading" size="50" />
    <div v-else class="content">
      <div class="left-panel">
        <div class="filters">
          <div class="search">
            <div class="icon">
              <img src="../assets/icons/Search.svg" />
            </div>
            <input
              type="search"
              name="search"
              id="search"
              placeholder="Search everything"
            />
          </div>
          <CustomSelect
            :options="[
              { value: 'ff', label: 'Sent' },
              { value: 'ff', label: 'Received' }
            ]"
            default="Sent and Received"
            noMargin
          />
        </div>
        <div class="tabs">
          <div class="tab-nav">
            <div class="nav active">
              <div class="title">
                <span class="text">Offers</span>
              </div>
              <span class="underline"></span>
            </div>
            <div class="nav">
              <div class="title">
                <span class="text">In Progress</span>
                <div class="badge">
                  <span>1</span>
                </div>
              </div>
              <span class="underline"></span>
            </div>
            <div class="nav">
              <div class="title">
                <span class="text">Completed</span>
              </div>
              <span class="underline"></span>
            </div>
          </div>
          <div class="tab-content">
            <div class="cards-wrapper">
              <div class="cards">
                <div
                  v-for="({ id, avatarUrl, name, track, message, createdAt },
                  i) in deals"
                  :key="i"
                  class="card"
                  :class="{ 'selected-card': id === active }"
                  @click="openDeal(id)"
                >
                  <div class="card-image" v-if="avatarUrl">
                    <img :src="avatarUrl" />
                  </div>
                  <div class="card-initials" v-else>
                    {{ name | initials }}
                  </div>
                  <div class="card-details">
                    <div class="header">
                      <span class="name">{{ name }}</span>
                      <span class="date">{{ createdAt }}</span>
                    </div>
                    <div class="subtitle">
                      <span>{{ track }}</span>
                    </div>
                    <div class="text">
                      <span>{{ message }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DealPreview />
    </div>
  </div>
</template>

<script>
import DealPreview from "@/components/DealPreview.vue";
import CustomSelect from "@/components/Select.vue";
import Loader from "@/components/Loader.vue";

import GET_DEALS from "@/api/queries/GET_DEALS.gql";

export default {
  name: "Deals",
  components: {
    CustomSelect,
    DealPreview,
    Loader
  },
  data() {
    return {
      showModal: false
    };
  },
  computed: {
    active() {
      return this.$route.query.view;
    }
  },
  filters: {
    initials(name) {
      return name
        .split(" ")
        .slice(0, 2)
        .map(el => el.charAt(0).toUpperCase())
        .join("");
    }
  },
  methods: {
    openDeal(id) {
      this.$router.push({ name: "Deals", query: { view: id } });
    }
  },
  apollo: {
    deals: {
      query: GET_DEALS,
      variables() {
        return { userId: this.$store.getters["account/getUserId"] };
      },
      update({ deals }) {
        return deals.map(
          ({
            id,
            artistFrom,
            artistTo,
            track_title: track,
            messages,
            created_at
          }) => {
            const isFromMe = !!(
              artistFrom.artist_users[0].user_id ===
              this.$store.getters["account/getUserId"]
            );
            const { name, avatar_url: avatarUrl } = isFromMe
              ? artistTo
              : artistFrom;

            let message = "";
            if (messages[0]) {
              const {
                artistFrom,
                message: chatMessage,
                system_message,
                file_id
              } = messages[0];
              const isFromMe = !!(
                artistFrom.artist_users[0].user_id ===
                this.$store.getters["account/getUserId"]
              );

              if (system_message) {
                message = isFromMe
                  ? system_message.sender
                  : system_message.recipient;
              } else {
                message = isFromMe
                  ? "You: "
                  : `${artistFrom.name.split(" ")[0]}: `;
                message +=
                  !chatMessage && file_id ? "Shared a file" : chatMessage;
              }
            }

            const date = new Date(created_at);
            const createdAt = date.toLocaleDateString("en-US", {
              month: "long",
              day: "numeric"
            });

            return {
              id,
              name,
              track,
              avatarUrl,
              message,
              createdAt
            };
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/_mixins.scss";
.container {
  width: 100%;
  height: 100%;
  .content {
    display: grid;
    grid-template-columns: 477px 1fr;
    height: 100vh;
    overflow: hidden;
    .left-panel {
      // padding: 60px;
      .filters {
        display: flex;
        padding: 60px;
        padding-bottom: 0;
      }
      .tabs {
        margin-top: 30px;
        .tab-nav {
          display: flex;
          justify-content: space-between;
          margin-bottom: 32px;
          padding: 0 60px;
          .nav {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin: 0 24px;
            .title {
              display: flex;
              padding: 0 10px;
              .text {
                @include category-medium();
                color: $text-additional;
              }
              .badge {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px;
                height: 12px;
                background: $accent;
                border-radius: 50%;
                margin-top: -4px;
                margin-left: -2px;
                span {
                  color: $white;
                  font-size: 10px;
                  line-height: 10px;
                }
              }
            }
            .underline {
              margin-top: 8px;
              height: 2px;
            }
            &.active {
              .title {
                .text {
                  color: $text;
                }
              }
              .underline {
                margin-top: 8px;
                width: 100%;
                height: 2px;
                background: $accent;
                border-radius: 50px;
              }
            }
          }
        }
        .tab-content {
          overflow-y: auto;
          overflow-x: hidden;
          height: calc(100vh - 196px);
          padding: 0 60px;
          padding-right: 44px;
          margin-right: 16px;
          scrollbar-width: thin;
          scrollbar-color: $accent rgba(#cdcccc, 0.3);
          &::-webkit-scrollbar {
            /* width of the entire scrollbar */
            width: 6px;
          }
          &::-webkit-scrollbar-track {
            /* color of the tracking area */
            @include background-opacity(#cdcccc, 0.3);
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            /* color of the scroll thumb */
            background-color: $accent;
            /* roundness of the scroll thumb */
            border-radius: 5px;
          }
          .cards-wrapper {
            .cards {
              .card {
                display: flex;
                align-items: center;
                padding: 11px 16px;
                margin-bottom: 21px;
                border: 1px solid transparent;
                cursor: pointer;
                &.active,
                &:hover {
                  background: #ffffff;
                  border: 1px solid #ededed;
                  box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
                  border-radius: 10px;
                }
                .card-image {
                  width: 63px;
                  height: 63px;
                  overflow: hidden;
                  border-radius: 50%;
                  min-width: 63px;
                  min-height: 63px;
                  img {
                    object-fit: cover;
                  }
                }
                .card-initials {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 63px;
                  width: 63px;
                  font-family: sans-serif;
                  font-size: 27px;
                  border-radius: 50%;
                  background-color: $accent;
                  color: $white;
                }
                .card-details {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  margin-left: 16px;
                  max-width: 246px;
                  .header {
                    display: flex;
                    .name {
                      @include category-medium(16px, 16px);
                      flex: 1;
                    }
                    .date {
                      font-size: 12px;
                      line-height: 12px;
                      letter-spacing: -0.22px;
                      color: $text-additional;
                    }
                  }
                  .subtitle {
                    @include date-time-light();
                    color: $text-additional;
                    margin-top: 6px;
                  }
                  .text {
                    @include body-light();
                    margin-top: 4px;
                    span {
                      display: block;
                      max-width: 98%;
                      white-space: nowrap;
                      overflow: hidden !important;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
              .selected-card {
                background: #ffffff !important;
                border: 1px solid #ededed;
                box-shadow: 0px 16px 24px rgba(59, 59, 59, 0.08);
                border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
